@layer tailwind {
  @tailwind base;
}
@tailwind components;
@tailwind utilities;

@property --loader-bg {
  syntax: '<color>';
  inherits: true;
  initial-value: transparent;
}

html {
  height: 100%;
}

body {
  color: var(--mantine-color-dark-9);
  background: var(--mantine-color-gray-0);
  min-height: 100%;
}

.button-theme {
  border: 1px solid var(--mantine-color-dark-9);

  &:hover {
    background-color: var(--mantine-color-teal-3);
  }
  &:active {
    background-color: var(--mantine-color-teal-5);
  }
  &:disabled,
  &[data-disabled] {
    background-color: var(--mantine-color-gray-2);
    color: var(--mantine-color-gray-6) !important;
    border: 1px solid var(--mantine-color-gray-6);
  }

  &[data-variant='outline'] {
    border: 1px solid var(--mantine-color-gray-6);
  }

  &[data-variant='outline']:hover,
  &[data-variant='transparent']:hover {
    background-color: var(--mantine-color-teal-0);
  }

  &[data-variant='outline']:active,
  &[data-variant='transparent']:hover {
    background-color: var(--mantine-color-teal-1);
  }

  &[data-variant='outline'][data-disabled] {
    background-color: var(--mantine-color-white);
    border: 1px solid var(--mantine-color-gray-6);
    color: var(--mantine-color-gray-6) !important;
  }

  &[data-variant='transparent'] {
    border: none;
    text-decoration: underline;
    text-decoration-color: var(--mantine-color-dark-9);
    text-underline-offset: 0.2rem;
    padding: 0 18px;
  }

  &[data-variant='transparent'][data-disabled] {
    border: none;
    background-color: var(--mantine-color-white);
    color: var(--mantine-color-gray-6) !important;
  }
}

.input-theme:focus {
  border: 1px solid var(--mantine-color-gray-6);
}

.checkbox-theme {
  border: 1px solid transparent;
}

.checkbox-theme[data-checked='true'] {
  border: 1px solid var(--mantine-color-dark-9);
  border-radius: 5px;
}

.loader-theme::after {
  border-color: var(--loader-color) var(--loader-color) var(--loader-color)
    var(--loader-bg);
}
