.logo :global(path) {
    fill: #22CB83;
}

.activeLink :global(svg) {
    color: var(--mantine-color-blue-6);
    background-color: var(--mantine-color-blue-0);
    border-radius: 4px;
}

.link:hover {
    background-color: var(--mantine-color-gray-0);
}
